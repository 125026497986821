import axios from './index'

export const GET_PROJECT_ID = 'kiosks/data/'
export const SET_MOTION_CHECK = 'contents/motion_check/'
export const GET_CONTENTS_LIST = 'contents/datalist/'
export const GET_FILEGROUP_LIST = 'file_group/datalist/'
export const GET_FILE = 'files/'
export const GET_COMPONENT_LIST = 'components/datalist/'
export const GET_ANIMATION_LIST = 'animations/datalist/'
export const GET_TEXT_TO_SPEECH = 'contents/text_to_speech/'
export const SET_QUIZ = 'contents/quiz/'
export const SET_HIDDEN_CATCH = 'contents/hidden_catch/'
export const POST_CALL_HELPER = 'contents/call_helper/'
export const POST_CALL_EMERGENCY = 'contents/call_emergency/'

// 백엔드에서 넘어옴
export const GET_SPEECH_TO_TEXT = 'files/speech_to_text/'
export const SET_CHANGE_LANGUAGE = 'kiosks/change_language/'

export default {
  axiosGetProjectId (serial) {
    return axios.post(GET_PROJECT_ID, { serial })
  },
  axiosSetMotionCheck (params) {
    return axios.post(SET_MOTION_CHECK, params)
  },
  axiosGetContentsList (projectId) {
    return axios.get(GET_CONTENTS_LIST + projectId + '/')
  },
  axiosGetFileGroupList (projectId) {
    return axios.get(GET_FILEGROUP_LIST + projectId + '/')
  },
  axiosGetFile (id) {
    return axios.get(GET_FILE + id + '/')
  },
  axiosGetComponentList (projectId) {
    return axios.get(GET_COMPONENT_LIST + projectId + '/')
  },
  axiosGetAnimationList (projectId) {
    return axios.get(GET_ANIMATION_LIST + projectId + '/')
  },
  axiosGetTextToSpeech (params) {
    return axios.post(GET_TEXT_TO_SPEECH, params)
  },
  axiosSetQuiz (params) {
    return axios.post(SET_QUIZ, params)
  },
  axiosSetHiddenCatch (params) {
    return axios.post(SET_HIDDEN_CATCH, params)
  },
  axiosPostCallHelper () {
    return axios.post(POST_CALL_HELPER)
  },
  axiosPostCallEmergency () {
    return axios.post(POST_CALL_EMERGENCY)
  },

  // backend에서 넘어옴
  axiosGetSpeechToText (params) {
    return axios.post(GET_SPEECH_TO_TEXT, params, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  axiosSetChangeLanguage (params) {
    return axios.post(SET_CHANGE_LANGUAGE, params)
  }

}
